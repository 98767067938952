import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/semantic/Layout"
import PostTags from "../components/PostTags/PostTags";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import {Grid} from "semantic-ui-react";


export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID;
    }
    function heroImage(imageName)
    {
      if (imageName) {
        return (
          <div style={{width:'auto', height:'auto', display:'inline' }}>
            <img src={post.cover} style={{width:'100%', height:'80%'}} alt={post.cover} />
          </div>
          )
      };
    }
    return (
      <Layout>
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          <div style={{backgroundColor:'#FFFFFF', color:"#000000"}}>
          <Grid style={{ padding: '0.1em 0em' }} vertical container stackable verticalAlign='top'>
            <Grid.Row>
              <Grid.Column>
            {heroImage(post.cover)}
            <div style={{fontFamily:'Lato', fontSize:'21px', display:'fluid' }} dangerouslySetInnerHTML={{ __html: postNode.html }} />
            <div style={{padding: '0.5em 0em'}}>
              <PostTags tags={post.tags} />
            </div>
            <div style={{fontFamily:'Lato', fontSize:'17px', padding: '0.5em 0em'}}>
              <span>By </span>
              <span style={{color:'#666'}}>{post.author}</span><span>,&nbsp;&nbsp;</span>
              <span>Posted </span><span style={{color:'#666'}}>{post.date}</span>
            </div>

            </Grid.Column>
            </Grid.Row>
            </Grid>
            </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        author 
        date (formatString: "MMM Do YYYY") 
        category
        tags
      }
      fields {
        slug
        date (formatString: "MMM Do YYYY") 
      }
    }
  }
`;
